<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      :needBtnLoading="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
      @handleChange="handleChange"
      @inputChange="inputChange"
    >
    <el-form-item class="el_from" label="C端小程序是否使用" slot="catalog" style="margin: 0" label-width="150px">
        <el-select v-model="isCustomHome" size="mini" placeholder="请选择" clearable>
          <el-option v-for="item in catalogData" :key="item.label" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
    <div slot="AddSlot" >
      <el-button size="small" type="primary" icon="el-icon-plus" @click="$router.push('/oldBrand/add')">添加品牌</el-button>
    </div>
    </GlobalForm>
  </div>
</template>

<script>
import {formValidateMobile} from '@/utils/form-validate'
// import _api from "@/utils/request";
export default {
  name: 'Form',
  props:{
    classList:Array
  },
  data() {
    let _this = this
    return {
      isCustomHome:"",
      brandName:"",
      initData: null,
      machineTypeId: null,
      catalogData: [
        {
          label: "全部",
          value: null
        },
        {
          label: "是",
          value: true
        },
        {
          label: "否",
          value: false
        }
      ],
      formItemList: [
        {
          key: 'staffName',
          type: 'input',
          labelName: '品牌名称',
          placeholder: '请输入品牌名称',
          clear: true,
          clearFun:function () {
            _this.brandName = ""
            const SeachParams = {
              brandName:"",
              machineTypeId: _this.machineTypeId,
              pageNum: 1,
              pageSize: 10,
            }
            _this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
          }
        },
        {
          key: 'machineTypeId',
          type: 'select',
          labelName: '所属分类',
          placeholder: '请选择分类',
          option:this.classList,
          clear: true,
          clearFun:function () {
            _this.machineTypeId = ""
            const SeachParams = {
              brandName: _this.brandName,
              machineTypeId: "",
              pageNum: 1,
              pageSize: 10,
            }
            _this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
          }
        },
        {slotName:"catalog"},
        {slot:"AddSlot"}
      ],
      formRules: {
        mobile: [{
          validator: formValidateMobile,
          trigger: 'blur'
        }]
      }
    }
  },
  created(){
    this.classList.forEach(ele => {
      ele.label = ele.name
      ele.value = ele.id
    })
  },
  methods: {
    //查询
    handleConfirm(data,cd) {
      if(data){
        this.brandName = data.staffName
        this.machineTypeId = data.machineTypeId
      }else{
        this.brandName = ""
      }
      const SeachParams = {
        brandName:this.brandName,
        machineTypeId: this.machineTypeId,
        isCustomHome:this.isCustomHome,
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if (typeof cd === 'function') {
            cd()
          }
        },
      } 
      this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
      // this.$emit('handleConfirm',SeachParams)
    },
    // 筛选条件
    handleChange(item,value){
      if(item.machineTypeId){
        this.machineTypeId = value
      }
    },
    inputChange(item,value) {
      if(item.brandName){
        this.brandName = value
      }
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo{}
</style>
