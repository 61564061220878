<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="this.seachDataList"
      :currentPage="page.pageNum"
      :total="this.page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="是否苹果系统" slot="operation" align="center">
        <template slot-scope="{ row }">
          <span>
            {{row.isIos == '01'?'是':'否'}}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="B端品牌图标" slot="operation" align="center">
        <template slot-scope="{ row }">
          <span>
            <img :src="row.logo" width="40" height="40" />
          </span>
        </template>
      </el-table-column>
      <el-table-column label="C端品牌图标" slot="operation" align="center">
        <template slot-scope="{ row }">
          <span>
            <img :src="row.customLogo" width="40" height="40" />
          </span>
        </template>
      </el-table-column>
      <el-table-column label="排序" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-input-number size="small" v-model="row.sort" @change="handleChangeNum(row)"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="是否启用" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-switch inactive-color="#bebebe" @change="handleSwitch(row,'enable')" v-model="row.isEnable"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="是否热门" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-switch inactive-color="#bebebe" @change="handleSwitch(row,'hot')" v-model="row.isHot"></el-switch>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" @click="handleBtn(row, 'edit')" :disabled="row.isEnable">编辑</el-button>
        </template>
      </el-table-column>
    </GlobalTable>

    <!--弹框-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="380px"
      @closed="handleDialogClosed"
    >
      <div style="font-size:16px;color:#666666;text-align:center;height:100px;line-height:100px">确认要执行该操作吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="handleSuccess">确定</el-button>
        <el-button @click.native="handleClose">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MinXinRequest from "@/mixins/request";
import _api from "@/utils/request";
export default {
  name: "Table",
  mixins: [MinXinRequest],
  data() {
    return {
      seachDataList: [],
      ResidentEnable: {
        id: "",
        isEnable: ""
      },
      ResidentHot:{
        id: "",
        isHot: ""
      },
      pageNum:1,
      //弹窗
      dialogVisible: false, // 弹框的出现与否
      dialogTitle: "是否上下架商品", // 标题
      tableColumns: [
        { label: "所属分类", prop: "machineTypeName" },
        { label: "品牌名称", prop: "name" },
        { slotName: "operation" }
      ]
      //   currentRow: {}
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    seachTotal() {
      return this.$store.state.tagsView.seachTotal;
    },
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    }
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.pageNum = 1
      this.handleCurrentChange();
    }
  },
  methods: {
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val
        this.page.pageNum = val;
      } else {
        this.page.pageNum = 1;
      }
      const baseRequest = {
        brandName: this.SeachParams.brandName,
        machineTypeId: this.SeachParams.machineTypeId,
        isCustomHome:this.SeachParams.isCustomHome,
        pageNum: this.page.pageNum,
        pageSize: this.SeachParams.pageSize
      };
      console.log(baseRequest);
      this.$emit("handleCurrentChange", val);
      _api.ResidentTable(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.pageNum = res.data.current;
          this.page.total = res.data.total;
          this.$store.commit("tagsView/POST_TOTAL", res.data.total);
          this.$store.commit("tagsView/POST_RESIDENT", this.seachDataList);
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    // 表格按钮操作
    handleBtn(row, type) {
      console.log("表格操作", type, row);
      this.$store.commit("tagsView/POST_RESIDENT_FORMDATA", row);
      this.$router.push({
        path: "/oldBrand/add",
        query: { type: "edit", id: row.id }
      });
    },
    //商品上下架
    handleSwitch(row,val) {
      if(val === "enable"){
        this.dialogTitle = "上下架品牌";
        this.ResidentEnable.id = row.id;
        this.ResidentEnable.isEnable = row.isEnable;
      }else{
        this.dialogTitle = "是否热门";
        this.ResidentHot.id = row.id;
        this.ResidentHot.isHot = row.isHot;
      }
      this.dialogVisible = true;

    },
    // 商品热门
    hotSwitch(row) {
        console.log(row);
        _api.ResidentHot({id:row.id,isHot:row.isHot}).then(res => {
          this.$message({
            message: res.msg,
            type: 'success'
          });
        })
    },
    // //弹窗取消
    handleClose() {
      this.dialogVisible = false;
    },
    //弹窗确定
    handleSuccess() {
      this.dialogVisible = false;
      if(this.dialogTitle === '上下架品牌'){
        _api.ResidentEnable(this.ResidentEnable).then(res => {
          if (res.code === 1) {
            this.$message.success("操作成功");
          } else {
            this.$message.error("操作失败");
          }
        });
      }else{
        _api.ResidentHot(this.ResidentHot).then(res => {
          if (res.code === 1) {
            this.$message.success("操作成功");
          } else {
            this.$message.error("操作失败");
          }
        })
      }
      
    },
    handleDialogClosed() {
      console.log(this.pageNum);
      this.dialogVisible = false;
      this.handleCurrentChange(this.pageNum);
    },
    //排序
    handleChangeNum(val) {
      const params = {
        id: val.id,
        sort: val.sort
      };
      _api.ResidentSort(params).then(res => {
        if (res.code === 1) {
          this.handleCurrentChange(this.pageNum);
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  // filters:{
  //   classListFilter:function (val,classList) {
  //     if(!val){
  //       return
  //     }
  //     let value = []
  //     classList.forEach(ele => {
  //       val.forEach(item => {
  //         if(ele.id == item){
  //           value.push(ele.name)
  //         }
  //       })
  //     })
  //     return value.toString()
  //   }
  // }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
}
</style>
